import { Link} from 'react-router-dom';

function Footer() {
    return (
        <div>
        <footer id="footer" className="footer">   
    <div className="footer-newsletter">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 text-center">
            <h4>Our Newsletter</h4>
            <p>Welcome to my newsletter, where I am bring you the latest updates, offers, and discounts in website design and developent. </p>
          </div>
          <div className="col-lg-6">
            <form action="#" method="post">
<input type="email" name="email" />
<input type="submit" value="Follow Me" />
            </form>
          </div>
        </div>
      </div>
    </div>
<div className="footer-top">
<div className="container">
<div className="row gy-4">
<div className="col-lg-3 col-md-12 footer-info">
<Link to="index.html" className="logo d-flex align-items-center">
<img src="assets/img/logo.png" alt="Website Designing Company In Gurgaon" /><span></span></Link>
<p>I'm a Full-stack website developer since 2013, I have been 500+ Website completed beautiful responsive  for our clients. I am able to work effectively in a team or on my Own. I pride myself on being able to use my knowledge and initiative to deliver high quality results to tight deadlines.</p>
<div className="social-links mt-3">
<a href="https://twitter.com/pankajsingh642" target="_blank" className="twitter"><i className="bi bi-twitter"></i></a>
<a href="https://www.facebook.com/pankaj171cse" target="_blank" className="facebook"><i className="bi bi-facebook"></i></a>
<a href="https://www.instagram.com/pankajsingh642" target="_blank" className="instagram"><i className="bi bi-instagram"></i></a>
<a href="https://www.linkedin.com/in/pankj/" target="_blank" className="linkedin"><i className="bi bi-linkedin"></i></a>
<a href="https://in.pinterest.com/pankajsingh642/" target="_blank" className="pinterest"><i className="bi bi-pinterest"></i></a>
<a href="https://api.whatsapp.com/send?phone=917838704822&text=Hello%20Pankaj" target="_blank"><i className="bi bi-whatsapp" aria-hidden="true"></i></a>
</div>
</div>
<div className="col-lg-2 col-6 footer-links">
<h4>Useful Links</h4>
<ul>
<li><i className="bi bi-chevron-right"></i> <Link to="/">Home</Link></li>
<li><i className="bi bi-chevron-right"></i> <Link to="about-me">About Me</Link></li>
<li><i className="bi bi-chevron-right"></i> <Link to="services">Services</Link></li>
<li><i className="bi bi-chevron-right"></i> <Link to="portfolios">Portfolio</Link></li>
<li><i className="bi bi-chevron-right"></i> <Link to="certificate">Certificate</Link></li>
</ul>
</div>
<div className="col-lg-2 col-6 footer-links">
<h4>Useful Links</h4>
<ul>
<li><i className="bi bi-chevron-right"></i> <a href="https://www.linkedin.com/in/pankj" target="_blank">Linkedin</a></li>
<li><i className="bi bi-chevron-right"></i> <a href="https://api.whatsapp.com/send?phone=917838704822&text=Hello%20Pankaj" target="_blank">Whatsapp</a></li>
<li><i className="bi bi-chevron-right"></i> <a href="https://www.fiverr.com/pankajsingh642" target="_blank">Fiverr.com</a></li>
<li><i className="bi bi-chevron-right"></i> <a href="https://www.freelancer.in/u/pankajsingh642" target="_blank">Freelancer.com</a></li>
<li><i className="bi bi-chevron-right"></i> <a href="https://www.instagram.com/pankajsingh642" target="_blank">Instagram.com</a></li>
</ul>
</div>
<div className="col-lg-2 col-6 footer-links">
<h4>Our Services</h4>
<ul>
<li><i className="bi bi-chevron-right"></i> <Link to="web-designer-in-gurgaon">Web Design</Link></li>
<li><i className="bi bi-chevron-right"></i> <Link to="web-developer-in-gurgaon">Web Development</Link></li>
<li><i className="bi bi-chevron-right"></i> <Link to="ppc-services-in-gurgaon">PPC Services</Link></li>
<li><i className="bi bi-chevron-right"></i> <Link to="social-media-marketing-freelancers-in-gurugram">Social Media Marketing</Link></li>
<li><i className="bi bi-chevron-right"></i> <Link to="graphic-designer-in-gurgaon">Graphic Design</Link></li>
</ul>
</div>
<div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
<h4>Get in touch about projects, collaborations or just for fun.</h4>
<p> 
Website Designing Company in Gurgaon, DLF Cyber City, 
Gurgaon Haryana,122002 <br/>
<strong>Phone:</strong> <a href="tel:+917838704822" target="_blank"><i className="fa fa-mobile" aria-hidden="true"></i> :+91-7838704822</a><br/>
<strong>Email:</strong><a href="mailto:pankajsingh642@gmail.com" target="_blank"><i className="fa fa-envelope-o" aria-hidden="true"></i> :pankajsingh642@gmail.com</a><br/>
</p>
</div>
</div>
</div>
</div>
<div className="container">
<div className="copyright"> &copy; Copyright 2013-2024 <strong><span>pankj.com</span></strong>. All Rights Reserved.
      </div>
    </div>
  </footer>
  <Link to="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></Link>
        </div>
        
    );
}
export default Footer;
