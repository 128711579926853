import { Link } from "react-router-dom";
import React from "react";
import Slider from "react-slick";

export default function faqs() {
  var settings = {
      dots: false,
      infinite: true,
      speed: 100,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay:true,
      autoplaySpeed:1500,
      initialSlide: 0,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
  return (
    <>
<section id="recent-blog-posts" className="recent-blog-posts">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>Certificate</h2>
          <p>Certification & Awards</p>
        </header>
        <div className="row">
       <Slider {...settings}>
          <div className="col-lg-4">
            <div className="post-box">
              <div className="post-img"><img src="assets/img/bsnl-industrial-visit.avif" alt="BSNL Industrial Visit" className="img-fluid" /></div>
              <span className="post-date">BSNL / 2-Sept-2011</span>
              <h3 className="post-title">BSNL(Bharat Sanchar Nigam Ltd)</h3>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="post-box">
              <div className="post-img"><img src="assets/img/CSI-personal-effectiveness.avif" alt="Personal Effectiveness" className="img-fluid" /></div>
              <span className="post-date">Personal Effectiveness / 16th-July-2011</span>
              <h3 className="post-title">Personal Effectiveness - Computer Society Of India</h3>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="post-box">
              <div className="post-img"><img src="assets/img/japanese-language-certificate.avif" alt="Japanese Language" className="img-fluid" /></div>
              <span className="post-date">Awwwards / 2009-2013</span>
              <h3 className="post-title">Japanese Language - S&N Hayakawa, Japan</h3>
            </div>
          </div>
            <div className="col-lg-4 col-md-6">
              <div className="post-box">
              <div className="post-img">
                <img src={"assets/img/ericss-oncertificate.avif"} alt="Ericsson Tools Development" className="img-fluid"  />
                  </div>
                <span className="post-date">Awwwards / 2016</span>
              <h3 className="post-title">Tools Development - Ericsson</h3>        
              </div>
            </div>   
        </Slider>          
        </div>
      </div>
    </section>
    </>
  );
}
