export default function aboutus() {
  return (
    <>
             
 <section id="contact" className="contact">

      <div className="container" data-aos="fade-up">

        <header className="section-header">
          <h2>Contact</h2>
          <p>Connect with Me</p>
        </header>

        <div className="row gy-4">

          <div className="col-lg-6">

            <div className="row gy-4">
             
              <div className="col-md-6">
                <div className="info-box">
                  <i className="bi bi-telephone"></i>
                  <h3>Call Us</h3>
                  <p> <a href="tel:7838704822"><i className="fa fa-mobile" aria-hidden="true"></i> +91-7838704822</a></p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box">
                  <i className="bi bi-envelope"></i>
                  <h3>Email Us</h3>
                  <p><a href="mailto:pankajsingh642@gmail.com"><i className="fa fa-envelope-o" aria-hidden="true"></i> pankajsingh642@gmail.com</a></p>
                </div>
              </div>
               <div className="col-md-6">
                <div className="info-box">
                  <i className="bi bi-geo-alt"></i>
                  <h3>Address</h3>
                  <p>DLF Cyber City, <br/>Gurgaon Haryana,122002 </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box">
                  <i className="bi bi-clock"></i>
                  <h3>Open Hours</h3>
                  <p>Monday - Friday<br/>8:00PM - 03:00AM</p>
                </div>
              </div>
            </div>

          </div>

          <div className="col-lg-6">
            <form action="contact.php" method="post" className="php-email-form">
              <div className="row gy-4">

                <div className="col-md-6">
                <input type="text" className="hiddenclasss" name="title" />
                  <input type="text" name="name" id="name" className="form-control" placeholder="Your Name" required />
                </div>
                  <div className="col-md-6 ">
                  <input type="number" className="form-control" name="phone" id="phone" placeholder="Your Phone" required />
                </div>
                <div className="col-md-6">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                </div>

                <div className="col-md-6">
                  <input type="text" className="form-control" name="subject" placeholder="Subject" required />
                </div>

                <div className="col-md-12">
                  <textarea className="form-control" name="message" rows="6" placeholder="Message" required></textarea>
                </div>

                <div className="col-md-12 text-center">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">Your message has been sent. Thank you!</div>
                  <input type="submit" className="form-control submitbtn" name="subject" placeholder="Subject" value="Claim a Website(Rs: 2,499 Only)" onclick="return SubmitDetails();" />
                </div>

              </div>
            </form>

          </div>

        </div>

      </div>

    </section>

  {/*
  <section className="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14025.950155353696!2d77.08078733108762!3d28.494975919554356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d193840000001%3A0x73d45b324c28111e!2sPankaj%20%7C%20Website%20Designing%20Company%20In%20Gurgaon!5e0!3m2!1sen!2sin!4v1681029093335!5m2!1sen!2sin" width="100%" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</section>
  */}

    </>
  );
}
