import {Helmet} from 'react-helmet';
import AboutUs from "../Page/aboutus";
import Faqs from "../Page/faqs";
import Portfolios from "../Page/portfolios";
import Features from "../Page/features";
import Count from "../Page/count";
import Testimonials from "../Page/testimonials";
import Certificate from "../Page/Certificate";
import Contact from "../Page/contact";
import { Organization, Things, ProfessionalService } from "schema-dts";

const Professionalservice: ProfessionalService={
  "@context": "https://schema.org",
  "@type": "ProfessionalService",
  "name": "Pankaj Singh",
  "image": "https://www.pankj.com/assets/img/logo.png",
  "@id": "",
  "url": "https://www.pankj.com",
  "telephone": "07838704822",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "DLF Cyber City, Phase-3",
    "addressLocality": "Gurgaon",
    "postalCode": "122002",
    "addressCountry": "IN"
  } ,
  "sameAs": [
    "https://www.facebook.com/pankaj171cse",
    "https://twitter.com/pankajsingh642",
    "https://www.instagram.com/pankajsingh642",
    "https://www.youtube.com/@pankj_",
    "https://www.linkedin.com/in/pankj/",
    "https://in.pinterest.com/pankajsingh642/",
    "https://www.fiverr.com/pankajsingh642",
    "https://www.freelancer.in/u/pankajsingh642",
    "https://github.com/senior-web-designer",
    "https://pankj.com/"
  ] 
};

const organization: Organization = {
  "@context": "https://schema.org",
  "@type": "Website Designer",
  "name": "Pankaj Web Designer",
  "description": "I'm Full Stack Website Designer and Developer Since 2013, I have been designing beautiful and user friendly interfaces for mobile and web.",
   "image": "https://www.pankj.com/assets/img/logo.png",
  "logo": "https://www.pankj.com/assets/img/logo.png",
  "url": "https://www.pankj.com",
  "telephone": "+91-7838704822",
  "sameAs": ["https://www.linkedin.com/in/pankj","https://www.facebook.com/pankaj171cse", "https://www.instagram.com/pankajsingh642","https://twitter.com/pankajsingh642"],
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "U59, DLF Phase 3",
    "addressLocality": "Gurgaon",
    "postalCode": "122002",
    "addressCountry": "India"
}
};

const things: Things = {
 "@context": "http://schema.org",
        "@id": "#product",
        "@type": "Product",
        "name": "Website Designing Company In Gurgaon",
        "description": "I'm Full Stack Website Designer and Developer Since 2013, I have been designing beautiful and user friendly interfaces for mobile and web.",
        "image": "https://www.pankj.com/assets/img/logo.png",
        "url": "https://www.pankj.com",
        "brand": "Pankaj Web Designer",
    "sku": "Pankaj Web Designer",
         "offers": {
            "@type": "AggregateOffer",
            "url": "https://www.pankj.com",
            "priceCurrency": "INR", "lowPrice": "8000",
            "highPrice": "90000",
            "offerCount": "10000"
          },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "5",
            "bestRating": "5",
            "reviewCount": "1025"
        },
        "audience":{
            "@type":"audience",
            "description":"1025 Web Reviews",
            "audienceType" : "Pankaj Web Designer."
        }
};

function App1() {
  return (

   <>
   <Helmet>
  <title>Pankaj | Website Designing Company in Gurgaon</title>
  <meta name="description" content="Website Designing Company in Gurgaon, Delhi, Noida Your company’s website is the first line of inquiry for new customers, so why not Web design services from Us, one of Best Web Design and Development Companies in India."/>
  <meta name="keywords" content="website designing company in gurgaon, website developer in gurgaon, website development, web development company in gurgaon, website maker in gurgaon, website design company in gurgaon, pankaj web designer, pankj, freelance website designer, website designers in gurgaon, website developer near me, web designing company in gurgaon,"/>
  <meta property="og:type" content="website" />
<meta property="og:locale" content="en_US" />
<meta property="og:title" content="Pankaj - Website Designing Company in Gurgaon | website development company in gurgaon" />
<meta property="og:description" content="Website designing company in gurgaon,Delhi, Noida Your company’s website is the first line of inquiry for new customers, so why not Web design services from Us, one of Best Web Design and Development Companies in India." />
<meta property="og:site_name" content="Pankaj | Website designing company in gurgaon | website development company in gurgaon" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:site" content="@pankj" />
<meta name="twitter:title" content="Pankaj | Website Designing Company in Gurgaon | website development company in gurgaon" />
<meta name="twitter:description" content="Website designing company in gurgaon, Delhi, Noida Your company’s website is the first line of inquiry for new customers, so why not Web design services from Us, one of Best Web Design and Development Companies in India." />
<meta name="Distribution" content="Global" />
<meta name="Robots" content="follow, index, all" />
<meta name="googlebot" content="index, follow" />
<meta http-equiv="Expires" content="never" />
<meta name="document-type" content="public" />
<meta name="revisit-after" content="daily" />
<meta name="country" content="India" />
<meta name="Language" content="English" />
<meta name='rating' content="general" />
<link rel="canonical" href="https://www.pankj.com" />
<meta name="google-site-verification" content="v1NPNyoW9kqW8_e8pf1FrUJSlfjVKw0tdsA4cs6gJ-8" />
<meta name="author" content="pankj.com" />
<meta name="theme-color" content="#000" />

</Helmet>
<section id="hero" className="hero d-flex align-items-center fixedabove">
<video
                className="mainsliderbg"
                autoPlay
                muted
                loop={true} style={{width:'100%'}}>
                <source src="assets/img/bg.mp4" type="video/mp4" />
                <source src="bg.ogg" type="video/ogg" />
  </video>
<div className="mainsliderbg">
<div className="container-fluid">
<div className="row">
<div className="col-lg-6 d-flex flex-column">
<div className="multiple_effects" id="text3d">Website <span></span></div>
<div className="content_sec" data-aos="fade-up" data-aos-delay="400">If you are looking for a <h1 className="headerdesign">Website Designing Company in Gurgaon</h1>, then you are in the right place. Hello, I am pankaj with 10+ years of experience in website design & development. you can hire me as a freelancer for your dream projects.</div>
<div data-aos="fade-up" data-aos-delay="600">
<div className="text-center text-lg-start">
<a href="#about" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
<span>About Me</span>
<i className="bi bi-arrow-right"></i>
</a>
</div>
</div>
{/*
 <div className="waviy">
   <span>Zyuyyuyu</span>
  </div>
 <div className="web_ment_loader">
  <span>W</span>
  <span>e</span>
  <span>b</span>
  <span>ㅤ</span>
  <span>D</span>
  <span>e</span>
  <span>s</span>
  <span>i</span>
  <span>g</span>
  <span>n</span>
  <span>e</span>
  <span>r</span>
</div>
<div id="BrushCursor">
<div className="p p1">Hello World</div>
<div className="p p2">Hello World</div>
<div className="p p3">
Hello World
<div className="cursor"></div>
</div>
</div>
<div className="box_inners">
<div className="inner">
<span>Hello World</span>
</div>
<div className="inner">
<span>Hello World</span>
</div>
</div>
<h2 className="multiple_effects">I am a Web-Developer and <span></span></h2>
<h2 className="multieffecctss">
<span>always be</span>
<div className="message">
    <div className="word1">close</div>
    <div className="word2">code</div>
    <div className="word3">creating</div>
  </div>
</h2>
  <div className="row">
  <div className="col-md-12">
      <span className="title-word title-word-1">This </span>
    <span className="title-word title-word-2">is </span>
    <span className="title-word title-word-3">Pankaj </span>
    <span className="title-word title-word-4">Singh </span>
  </div>
</div>
*/}
</div>
<div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
<img src="assets/img/hero-img.png1" className="img-fluid" alt="" />
<div className="wrapper">
<div className="img-area">
<div className="inner-area">
<img src="../assets/img/123.png" alt="Pankaj Singh" />
</div>
</div>
{/*
<div className="name">Text Name</div>
<div className="buttons">
<button>Message</button>
<button>Subscribe</button>
</div>
<div className="social-share">
<div className="row">
        <i className="far fa-heart"></i>
        <i className="icon-2 fas fa-heart"></i>
        <span>20.4k</span>
      </div>
      <div className="row">
        <i className="far fa-comment"></i>
        <i className="icon-2 fas fa-comment"></i>
        <span>14.3k</span>
      </div>
      <div className="row">
        <i className="fas fa-share"></i>
        <span>12.8k</span>
      </div>
    </div>
*/}
</div>
</div>
</div>
</div> 
}
</div>
</section>
<main id="main">
 <AboutUs />
<Count />
<Features />
<Faqs />
<Portfolios /> 
<Testimonials/>
<Certificate />
<Contact /> 
</main>
</>
  );
}
export default App1;
